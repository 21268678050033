<template lang="pug">
mixin custom-class-form
  .d-flex.align-items-center.flex-wrap
    .font-size-0--75.mb-2(v-if="title") {{ title }}
    .font-size-0--75.mb-2(v-if="!textAfterInput") {{ $t('addCustomClassText') }}
    input.simpleInput.w-100.ml-auto(type="text" v-model="value" :placeholder="$t('myCustomClass')")
    .font-size-0--75.mt-1(v-if="textAfterInput") {{ $t('addCustomClassText') }}

div
  .sidebar-input-wrapper
    +custom-class-form
</template>

<script>
  import { debounce } from 'lodash';
  import { mapState } from 'vuex';
  import itemMixin from '../../../mixins/item';

  export default {
    mixins: [itemMixin],
    props: {
      property: { type: String },
      showAccordion: { type: Boolean, default: true },
      title: { type: String },
      textAfterInput: { type: Boolean, default: false },
    },
    data() {
      return {
        originalClasses: [],
      };
    },
    computed: {
      ...mapState(['selectedElement']),
      value: {
        get() {
          return this.getValueOf(`data.customClasses`, '') || '';
        },
        set: debounce(function (value) {
          this.setValueOf(`data.customClasses`, value);
          window.om.bus.$emit('userInputChange', { property: this.property, value });
          this.applyCustomClass(value);
        }, 1000),
      },
    },
    mounted() {
      const element = this.getElement();
      this.originalClasses = Array.from(element.classList);
    },
    methods: {
      getElement() {
        const isElement = this.selectedElement.uid.startsWith('ele_');
        const workspaceFrame = document.querySelector('#workspaceFrame');
        let element;

        if (isElement) {
          element = workspaceFrame.contentWindow.document.querySelector(
            `[data-element="${this.selectedElement.uid}"]`,
          ).parentElement;
        } else {
          element = workspaceFrame.contentWindow.document.querySelector(
            `#${this.selectedElement.uid}`,
          );
        }
        return element;
      },
      applyCustomClass(value) {
        const element = this.getElement();
        const classes = value.split(' ');
        if (value) {
          element.classList.add(...this.originalClasses, ...classes);
        } else {
          element.className = '';
          element.classList.add(...this.originalClasses);
        }
      },
    },
  };
</script>
